import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    white: '#FFFFFF',
    beige: '#FFF6D2',
    blue: '#2B3FF6',
    salmon: '#FF7676',
    flavescent: '#FFE88D',
    green: '#008777',
    sand: '#F6ECE2',
    powderBlue: '#ADE2DE',
    mauve: '#DEAAF3',
    ripeMango: '#FFB828',
    keyLime: '#F8FF83',
    lightBeige: '#FEFAEB',
    red: '#C02502',
  },
  components: {
    Button: {
      sizes: {
        sm: {
          borderRadius: '50px',
          padding: '18.75px 30px',
          height: 'auto',
        },
        md: {
          borderRadius: '50px',
          padding: '25px 40px',
          height: 'auto',
        },
      },
      variants: {
        solid: {
          bg: 'blue',
          color: 'white',
          transition: 'background 0.2s ease-in-out',
          _hover: {
            background: 'green',
          },
          _focus: {
            background: 'green',
          },
        },
        outline: {
          bg: 'transparent',
          color: 'blue',
          borderWidth: '3px',
          borderColor: 'blue',
          transition: 'background color 0.2s ease-in-out',
          _hover: {
            bg: 'transparent',
            color: 'green',
            borderColor: 'green',
          },
          _focus: {
            bg: 'transparent',
            color: 'green',
            borderColor: 'green',
          },
        },
        round: {
          bg: 'white',
          width: '100px',
          height: '100px',
          borderRadius: '100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'transform 0.2s ease-in-out',
          boxShadow: 'md',
          color: 'black',
          _hover: {
            textDecoration: 'none',
            transform: 'scale(0.95)',
            background: 'white',
          },
        },
        ghost: {
          color: 'black',
          padding: 0,
          _hover: {
            textDecoration: 'underline',
            background: 'transparent',
          },
        },
        link: {
          color: 'inherit',
          padding: 0,
          textDecoration: 'underline',
          _hover: {
            background: 'transparent',
          },
        },
      },
    },
    Link: {
      baseStyle: {
        color: 'blue',
        _hover: {
          textDecoration: 'none',
        },
        _focus: {},
      },
      sizes: {
        sm: {
          borderRadius: '50px',
          padding: '18.75px 30px',
          height: 'auto',
        },
        md: {
          borderRadius: '50px',
          padding: '25px 40px',
          height: 'auto',
        },
      },
      variants: {
        button: {
          bg: 'blue',
          color: 'white',
          display: 'inline-block',
          marginRight: 'auto',
          fontWeight: 'bold',
          transition: 'background 0.2s ease-in-out',
          _hover: {
            background: 'green',
          },
          _focus: {
            background: 'green',
          },
        },
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            background: 'white',
            padding: '0.9375rem 1.25rem',
            height: 'auto',
            mb: '1.375rem', // Hold space for error message
            _focus: {
              borderColor: 'blue',
              boxShadow: '0 0 0 1px blue',
            },
          },
        },
      },
    },
    FormLabel: {
      baseStyle: {
        lineHeight: '1',
        fontWeight: '800',
        letterSpacing: '0',
        fontSize: ['1.125rem', '1.25rem', '1.25rem'],
      },
      variants: {
        large: {
          fontSize: ['1.875rem', '2.8125rem', '2.8125rem'],
          marginBottom: '1.25rem',
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          background: 'white',
          border: '1px solid',
        },
      },
    },
    Stepper: {
      baseStyle: {
        separator: {
          bg: 'black',

          '&[data-status=complete]': {
            bg: 'green',
          },
        },
        indicator: {
          '&[data-status=incomplete]': {
            borderColor: 'black',
          },
          '&[data-status=active]': {
            borderColor: 'green', // Active step
          },
          '&[data-status=complete]': {
            bg: 'green',
            color: 'chakra-inverse-text',
          },
          _focusVisible: {
            boxShadow: '0 0 0 4px rgba(66, 153, 225, 0.6)',
            borderColor: 'blue',
          },
        },
      },
    },
    FormError: {
      baseStyle: {
        text: {
          color: 'red',
          textTransform: 'capitalize',
          marginTop: '0.25rem',
          fontSize: '0.75rem',
          position: 'absolute',
          top: 'calc(100% - 1.375rem)', // Offset input marginBottom
          left: '0',
        },
      },
    },
  },
  styles: {
    global: {
      'html, body': {
        background: 'beige',
        color: 'gray.800',
        fontFamily: 'Poppins',
      },
    },
  },
  textStyles: {
    h1: {
      fontSize: ['1.875rem', '2.8125rem', '2.8125rem'],
      lineHeight: '1.1',
      fontWeight: '800',
      letterSpacing: '-1px',
    },
    h2: {
      fontSize: ['1.5rem', '1.875rem', '2.25rem'],
      lineHeight: '1',
      fontWeight: '600',
    },
    h3: {
      fontSize: ['1.25rem', '1.375rem', '1.375rem'],
      lineHeight: '1.2',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: ['1rem', '1.125rem', '1.25rem'],
      lineHeight: '1.2',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: ['0.875rem', '1rem', '1.125rem'],
      lineHeight: '1.2',
      fontWeight: 'bold',
    },
    h6: {
      fontSize: ['0.75rem', '0.875rem', '1rem'],
      lineHeight: '1.2',
      fontWeight: 'bold',
    },
  },
  space: {
    1: '0.0625rem',
    5: '0.3125rem',
    10: '0.625rem',
    20: '1.25rem',
    30: '1.875rem',
    40: '2.5rem',
    50: '3.125rem',
    60: '3.75rem',
    70: '4.375rem',
    80: '5rem',
    90: '5.625rem',
    100: '6.25rem',
  },
});
