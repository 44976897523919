import React from 'react';
import PhoneInput from 'react-phone-number-input/input';
import { Field } from 'formik';
import { Input, Box } from '@chakra-ui/react';

const PhoneInputField = ({ name }) => (
  <Box
    display='flex'
    alignItems='top'
  >
    <Box
      paddingX={2}
      paddingY={4}
      color='#545454'
      backgroundColor='#f7f7f7'
      border='1px solid #E2E8F0'
      borderRight='none'
      borderRadius='4px 0 0 4px'
      height='3.5rem'
    >
      +1
    </Box>
    <Field name={name}>
      {({ field, form }) => (
        <Input
          as={PhoneInput}
          {...field}
          maxLength='14'
          country='US'
          onChange={(value) => {
            form.setFieldValue(field.name, value);
          }}
          onBlur={() => {
            form.setFieldTouched(field.name, true);
          }}
          borderRadius='0 4px 4px 0 '
        />
      )}
    </Field>
  </Box>
);

export default PhoneInputField;
