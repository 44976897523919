import CryptoJS from 'crypto-js';

const today = new Date();
export const minimumDate = today.toISOString().substring(0, 10);

const EST_OFFSET_SECONDS = 5 * 3600; // 5 hours in seconds
const FIVE_PM_OFFSET_SECONDS = 17 * 3600; // 5pm in seconds

const DATE_FIELDS = ['next_visit', 'due_date', 'next_visit'];

// Converts `YYYY-MM-DD` date values to Unix timestamps in seconds
export const convertDatesToUnix = (values) => {
  DATE_FIELDS.forEach((field) => {
    if (values[field] && typeof values[field] === 'string') {
      const parts = values[field].split('-');
      const year = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // Months are 0-indexed in JavaScript
      const day = parseInt(parts[2], 10);
      const epochUTC = Date.UTC(year, month, day) / 1000.0;

      const epochEST5PM = epochUTC + FIVE_PM_OFFSET_SECONDS - EST_OFFSET_SECONDS;

      values[field] = epochEST5PM; // Set to 5 PM EST
    }
  });

  return values;
};

export const convertDatesInObject = (obj) => {
  const newObj = {};

  // Iterate over the keys of the object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Check if the key is one of the specified date keys
      if (DATE_FIELDS.includes(key)) {
        // Convert the Unix timestamp to YYYY-MM-DD format
        newObj[key] = formatDate(obj[key]);
      } else {
        // Copy the value as is
        newObj[key] = obj[key];
      }
    }
  }

  return newObj;
};

function formatDate(unixTimestamp) {
  // Create a new Date object using the Unix timestamp
  const date = new Date(unixTimestamp * 1000);

  // Get the year, month, and day from the date
  const year = date.getFullYear();
  // Add 1 to the month since getMonth() returns 0-11
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);

  // Format the date in 'YYYY-MM-DD'
  return `${year}-${month}-${day}`;
}

export const currentDate = Math.floor(new Date().getTime() / 1000);

export const stringToBoolean = (string) => {
  if (typeof string === 'boolean') return string;
  if (typeof string === 'string') {
    switch (string.toLowerCase().trim()) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return undefined;
    }
  }
  return undefined;
};

export const filterObject = (obj) => {
  const allowedKeys = [
    'email',
    'first_name',
    'last_name',
    'due_date',
    'phone_number',
    'agree_to_terms',
    'understand_contact_requirement',
    'messaging_preference',
    'physician_name',
    'clinic_name',
    'clinic_zip',
    'next_visit',
    'most_recent_utm_campaign',
    'most_recent_utm_medium',
    'most_recent_utm_source',
    'most_recent_utm_term',
    'most_recent_utm_lp',
    'step',
    'sessionToken',
    'first_page_viewed',
    'experiments',
    'platform',
  ];

  const filteredObj = {};

  // Iterate over each key in the object
  for (const key in obj) {
    // Check if the key is in the list of allowed keys
    if (allowedKeys.includes(key)) {
      // If the key is allowed, add it to the filtered object
      filteredObj[key] = obj[key];
    }
  }

  // Return the filtered object
  return filteredObj;
};

export const hashString = (string) => CryptoJS.SHA256(string).toString(CryptoJS.enc.Hex);

export const hashPhoneNumber = (phoneNumber) => {
  // Remove all non-digit characters from the phone number
  let cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

  // Check if the cleaned phone number starts with a country code
  if (cleanedPhoneNumber.startsWith('1') && cleanedPhoneNumber.length === 11) {
    // If the phone number starts with '1' and has 11 digits, it is already in E.164 format
    cleanedPhoneNumber = `+${cleanedPhoneNumber}`;
  } else if (cleanedPhoneNumber.length >= 10 && cleanedPhoneNumber.length <= 15) {
    // If the phone number has 10-15 digits, assume it is a valid phone number and format it to E.164
    cleanedPhoneNumber = `+1${cleanedPhoneNumber}`; // Assuming the country code is for the United States
  } else {
    // If the phone number doesn't meet the requirements, return null or throw an error
    return;
  }

  return hashString(cleanedPhoneNumber);
};

const calculateDateDifferenceInDays = (date1, date2) => {
  const diffInMilliseconds = Math.abs(date2 - date1);
  const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
  return diffInDays;
};

const calculateWeeksSinceConception = (dueDate) => {
  const currentDate = new Date();
  const dueDateObj = new Date(dueDate);
  const conceptionDate = new Date(dueDateObj.getTime() - 280 * 24 * 60 * 60 * 1000);

  const daysSinceConception = calculateDateDifferenceInDays(currentDate, conceptionDate);
  const weeksSinceConception = daysSinceConception / 7;

  return weeksSinceConception;
};

export const isQualifiedLead = (dueDate) => {
  const weeksSinceConception = calculateWeeksSinceConception(dueDate);
  return weeksSinceConception <= 20; // Pregnancy is 20 weeks or less
};

export const doctorInfoRequired = (dueDate) => {
  const weeksSinceConception = calculateWeeksSinceConception(dueDate);
  return weeksSinceConception > 20; // if true, pregnancy is more than 20 weeks
};
